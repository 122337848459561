import React, {
  useEffect,
  useState,
} from 'react';

import moment from 'moment-timezone';

import cloudyDay from '../../assets/images/CloudyDay.png';
import heavyRain from '../../assets/images/heavyRain.png';
import lightning from '../../assets/images/lightning.png';
import sunnyDay from '../../assets/images/sunnyDay.png';

const CountryWeather = ({ data }) => {
  const locationName = data.name;
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [name, setName] = useState("");
  const [DayOfWeek, setDayOfWeek] = useState([]);
  const [DateOfMonth, setDateOfMonth] = useState([]);
  const [weather, setWeather] = useState([]);
  useEffect(() => {
    const getLocationCoordinates = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            locationName
          )}&key=AIzaSyASdpNTLU5ZEqnl9eG0yP3n8GHmXGR0igM`
        );
        // console.log(response);
        const data = await response.json();
        if (data.status === "OK" && data.results && data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          const name = data.results[0].formatted_address;
          // console.log("name", name);
          setCoordinates({ lat, lng });
          setName({});
          // console.log(coordinates.lat,coordinates.lng);
          const urlW = `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lng}&appid=5c1d3b3059e6fab9ef0493c4d30eb82e&units=metric`;
          const responseWeather = await fetch(urlW);
          const dataW = await responseWeather.json();
          // console.log("sojdviojsdiojv", dataW.city.timezone)
          // console.log("datammmmmW1",dataW.list);
          // console.log("datammmmmW1",dataW.list[0]);
          // console.log("datammmmmW2",dataW.list[8]);
          // console.log("datammmmmW3",dataW.list[16]);
          // console.log("datammmmmW4",dataW.list[24]);
          var combinedWeather = [];

          // Push the desired elements into the combinedWeather array
          combinedWeather.push(dataW.list[0]);
          combinedWeather.push(dataW.list[8]);
          combinedWeather.push(dataW.list[16]);
          combinedWeather.push(dataW.list[24]);
          setWeather(
            combinedWeather
          );
        } else {
          console.error("No coordinates found for the location:", locationName);
        }
      } catch (error) {
        console.error("Error fetching coordinates:", error);
      }
    };

    getLocationCoordinates();
  }, [locationName]);

  const timezoneOffset = -36000;

  //   // Update the current time every second
    useEffect(() => {
        const intervalId = setInterval(() => {
            const newTime = moment().utcOffset(timezoneOffset / 60);
            // const formattedTime = newTime.format('h:mm:ss A');
            // const formattedDate1 = newTime.add(1, 'days').format('ddd');
            const formattedDate11 = newTime.format('ddd MM-DD');
            // const formattedDate2 = newTime.add(1, 'days').format('ddd');
            const formattedDate22 = newTime.add(1, 'days').format('ddd MM-DD ');
            // const formattedDate3 = newTime.add(1, 'days').format('ddd ddd');
            const formattedDate33 = newTime.add(1, 'days').format('ddd MM-DD');
            // const formattedDate4 = newTime.add(1, 'days').format('ddd');
            const formattedDate44 = newTime.add(1, 'days').format('ddd MM-DD');

            var day = [];
            day.push(formattedDate11);
            day.push(formattedDate22);
            day.push(formattedDate33);
            day.push(formattedDate44)
            setDayOfWeek(day);
         
        }, 1000);
        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, [timezoneOffset]);



  const weathermap = [
    {
      img: sunnyDay,
      day: "Today(Sun)",
      date: "Mar 6",
      weatherType: "Sunny",
      temp: "15-20℃",
      aqi: "AQI 67",
    },
    {
      img: cloudyDay,
      day: "Mon",
      date: "Mar 7",
      weatherType: "Cloudy",
      temp: "16-22℃",
      aqi: "AQI 71",
    },
    {
      img: lightning,
      day: "Tue",
      date: "Mar 8",
      weatherType: "Lightning",
      temp: "17-20℃",
      aqi: "AQI 65",
    },
    {
      img: heavyRain,
      day: "Wed",
      date: "Mar 9",
      weatherType: "Heavy rain",
      temp: "16-21℃",
      aqi: "AQI 70",
    },
  ];
  return (
    <div className="mt-5 pb-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 ps-sm-0">
            <div className="weather-div px-3 pt-3 pb-3 rounded-2">
              <div>
                <h3 className="mb-0 text-white fw-normal fs_21 opacity-88">
                  {locationName}
                </h3>
                <div className="hr_line mt-2"></div>
              </div>
              <div className="">
                <div className="weather-report-div mt-4 text-center d-flex align-items-center justify-content-between gap-5 pb-3">
                  {weather.map((value, index) => (
                    <div key={index}>
                      <div>
                        <p className="mb-0 text-white opacity-88 fs_17 ff_pingfang days-stroke">
                        
                        {DayOfWeek[index]}
                        </p>
                        <p className="mb-0 text-white opacity-50">
                          {value.weather[0].main}
                        </p>
                      </div>
                      <div className="mt-3">
                        {
                          value.weather[0].main === "Rain" ? <img width={70} src={heavyRain} alt="sun" />: value.weather[0].main === "Clouds" ?  <img width={70} src={cloudyDay} alt="sun" />:<img width={70} src={sunnyDay} alt="sun" />
                        }
                        {/* <img width={70} src={sunnyDay} alt="sun" />
                        <img width={70} src={cloudyDay} alt="sun" />
                        <img width={70} src={heavyRain} alt="sun" /> */}
                        <p className="ff_pingfang fw-semibold text-white fs-16 opacity-88 mb-0 text-nowrap">
                          {value.weatherType}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="mb-0 text-white ff_pingfang fs_15 opacity-68">
                          {value.main.temp}℃
                        </p>
                        <p className="mb-0 text-white ff_pingfang fs_15 opacity-68 mt-2">
                          {value.aqi}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <h3 className=" fw-medium ff_heebo fs_30">
              General weather overview
            </h3>
            <p className=" fs_16 fw-normal ff_heebo line_hight">
              {data.weatherOverview}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountryWeather;

import React, {
  useEffect,
  useState,
} from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Banner from '../../assets/images/hero_bottom_sad.png';
import hero_img1 from '../../assets/images/hero_img1.png';
import hero_img2 from '../../assets/images/hero_img2.png';
import ImgView3 from '../../assets/images/img-view-03.jpg';
import logo_img from '../../assets/images/logo_img.png';
import shed from '../../assets/images/shed.png';
import ImgView2 from '../../assets/images/slider_img.png';
import ImgView1 from '../../assets/images/slider_img1.png';
import { createFunction } from '../../services/Continents';

const HomePage = () => {
  const [formData, setformData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    reason: "",
    desiredDestination: "",
    accommodationPreference: "",
    budget: "",
    flightsIncluded: "",
    departingCity: "",
    adults: "",
    children: "",
    fromLocation: "",
    toLocation: "",
    vacationBrief: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    setformData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const createForm = async (e) => {
    e.preventDefault();
    console.log("zkdjzjsdoijiosjdzsdvzsd");

    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      phoneNumber: formData.phoneNumber,
      email: formData.email,
      reason: formData.reason,
      desiredDestination: formData.desiredDestination,
      accommodationPreference: formData.accommodationPreference,
      budget: formData.budget,
      flightsIncluded: formData.flightsIncluded,
      departingCity: formData.departingCity,
      adults: formData.adults,
      children: formData.children,
      fromLocation: formData.fromLocation,
      toLocation: formData.toLocation,
      vacationBrief: formData.vacationBrief,
    };

    try {
      const res = await createFunction(
        data,
      );
      console.log(res);
      alert("Thank you for connecting with us. We will get back to you shortly.")
      setformData({ 
        firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      reason: "",
      desiredDestination: "",
      accommodationPreference: "",
      budget: "",
      flightsIncluded: "",
      departingCity: "",
      adults: "",
      children: "",
      fromLocation: "",
      toLocation: "",
      vacationBrief: "",})
      window.scrollTo(0, 0);
      // createFormCallBack(res);
    } catch (error) {
      // toast.error("Failed to create lead. Please try again later.");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Container fluid className="p-0 position-relative">
        <section className="position-relative hero_bg_image">
          <img
            src={Banner}
            className="custom_hight position-absolute bottom-0 w-100"
          />
          <div className=" w-100">
            <Container
              fluid
              className="d-flex justify-content-md-end  justify-content-center flex-column min-vh-100 flex-grow-1"
            >
              <Container>
                <Row className=" position-relative pb-md-5 mb-md-5 z-index-1">
                  <Col className="col-md-6 col-12">
                    <div className="d-flex flex-column h-100 w-100 justify-content-center">
                      <h2 className=" custom_color ff_heebo fs_41 mt-5 fw-medium">
                        What we stand for
                      </h2>
                      <div className="text-black ff_heebo fs_20">
                      Serenity | Freedom | Energy | Diversity
                      </div>
                      <div className="text-black ff_heebo fs_14">
                      Mindfulness - Awakening - Togetherness
                      </div>
                    </div>
                  </Col>
                  <Col className="col-md-6 col-12 mt-5 mt-md-0">
                    <Container fluid>
                      <Row className="">
                        <Col className="col-sm-6 ">
                          <div className="rounded overflow-hidden">
                            <img src={hero_img1} className="img-fluid" />
                          </div>
                        </Col>
                        <Col className="pt-md-5 col-sm-6  ">
                          <div className="rounded overflow-hidden">
                            <img src={hero_img2} className="img-fluid" />
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Container>
          </div>
        </section>

        <section className="bg-section image_form pb-md-5 position-relative mb-4">
          <Container className="">
            <Row>
              <Col className="col-12 col-md-6">
                <div className="fs_40 fw-medium ff_heebo">
                  Planning your vacation has never been easier !
                </div>
                <div className="fs_16 fw-light ff_heebo">
                To begin, simply complete the form, and one of our expert agents will reach out to assist you.
                </div>
              </Col>
              <Col className="col-12 mt-4 mt-md-0 col-md-6">
                <div className="rounded-4 p-3 bg-white">
                  <Container fluid>
                    <div className="fs_33 text-brown ff_heebo mb-2 font-medium">
                      Travel with purpose
                    </div>
                  </Container>
                  <form onSubmit={createForm}>
                    <Container fluid>
                      <Row className="mb-2">
                        <Col className="col-12 col-md-6 mt-3">
                          <input required
                            type="text"
                            className="form-control py-3 px-3 rounded-0 ff_heebo border-0 bg-theme-primary"
                            placeholder="First Name"
                            value={formData?.firstName}
                            onChange={handleInputChange}
                            name="firstName"
                          />
                        </Col>
                        <Col className="col-12 col-md-6 mt-3">
                          <input required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Last Name"
                            value={formData?.lastName}
                            onChange={handleInputChange}
                            name="lastName"
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col className="col-12 col-md-6 mt-3">
                          <input required
                            type="number"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Phone"
                            value={formData?.phoneNumber}
                            onChange={handleInputChange}
                            name="phoneNumber"
                          />
                        </Col>
                        <Col className="col-12 col-md-6 mt-3">
                          <input required
                            type="email"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Email"
                            value={formData?.email}
                            onChange={handleInputChange}
                            name="email"
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col className="col-12 col-md-6 mt-3">
                          <input required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Reason for travel"
                            value={formData?.reason}
                            onChange={handleInputChange}
                            name="reason"
                          />
                        </Col>
                        <Col className="col-12 col-md-6 mt-3">
                          <input required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Desired Destination"
                            value={formData?.nadesiredDestinationme}
                            onChange={handleInputChange}
                            name="desiredDestination"
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col className="col-12 mt-3">
                          <input required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Desired Form Of Contact"
                            value={formData?.flightsIncluded}
                            onChange={handleInputChange}
                            name="flightsIncluded"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2 ">
                        <Col className="col-12 col-md-6 mt-3">
                          <input required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Accommodation preference"
                            value={formData?.accommodationPreference}
                            onChange={handleInputChange}
                            name="accommodationPreference"
                          />
                        </Col>
                        <Col className="col-12 col-md-6 mt-3">
                          <input required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Budget"
                            value={formData?.budget}
                            onChange={handleInputChange}
                            name="budget"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2 mt-3">
                        <Col>
                          <input required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="What airport/city will you be departing from (if you are needing airfare.)"
                            value={formData?.departingCity}
                            onChange={handleInputChange}
                            name="departingCity"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col className="col-12 col-md-6 mt-3">
                          <input required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Number Of Adults"
                            value={formData?.adults}
                            onChange={handleInputChange}
                            name="adults"
                          />
                        </Col>
                        <Col className="col-12 col-md-6 mt-3">
                          <input required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Number Of Children"
                            value={formData?.children}
                            onChange={handleInputChange}
                            name="children"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col className="col-12 col-md-6 mt-3">
                          <input required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="From"
                            value={formData?.fromLocation}
                            onChange={handleInputChange}
                            name="fromLocation"
                          />
                        </Col>
                        <Col className="col-12 col-md-6 mt-3">
                          <input required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="to"
                            value={formData?.toLocation}
                            onChange={handleInputChange}
                            name="toLocation"
                          />
                        </Col>
                      </Row>
                      {/* <Row className="mb-2">
                        <Col className="col-12 col-md-6 mt-3">
                          <input
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Phone"
                            value={formData?.name}
                            onChange={handleInputChange}
                            name="name"
                          />
                        </Col>
                        <Col className="col-12 col-md-6 mt-3">
                          <input
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="email"
                          />
                        </Col>
                      </Row> */}
                      <Row className="mb-2 mt-3">
                        <Col>
                          <textarea
                            className="form-control rounded-0 ff_heebo pt-3 px-3 border-0 bg-theme-primary"
                            placeholder="Vacation Brief"
                            value={formData?.vacationBrief}
                            onChange={handleInputChange}
                            name="vacationBrief"
                          ></textarea>
                        </Col>
                      </Row>
                      <Row className="">
                        <Col className="d-flex justify-content-center">
                          <button type='submit' className="btn ff_heebo btn-primary px-5 mt-4">
                            Submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="my-5">
          <Container>
            <Row>
              <Col className="text-center">
                <div className="fs_15 ff_heebo">PLAN YOUR TRIP</div>
                <div className="fs_30 ff_heebo color_primary font-medium">
                  Our Popular Destinations
                </div>
              </Col>
            </Row>
            <Row className="mt-md-5 mt-3 flex-column flex-md-row">
              <Col className="d-flex justify-content-center mt-4 mt-md-0 justify-content-md-start align-items-center col-md-4 col-12">
                <div className="">
                  <img src={ImgView1} width="297" className="img-fluid" />
                </div>
              </Col>

              <Col className="col-md-4 justify-content-center d-flex mt-4 mt-md-0 col-12">
                <div className="">
                  <img src={ImgView2} width="456" className="img-fluid" />
                </div>
              </Col>

              <Col className="d-flex align-items-center mt-4 mt-md-0 justify-content-center justify-content-md-end col-md-4 col-12">
                <div>
                  <img src={ImgView1} width="297" className="img-fluid" />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="text-center mt-3">
                <a href="" className="text-brown ff_heebo fs_22">
                  View All &rarr;
                </a>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="my-4">
          <Container className="bg-section-primary rounded-right-top p-md-5 p-3">
            <Row>
              <Col className="text-center fs_30 text-brown ff_heebo font-medium">
                Top Reviews For Our Services
              </Col>
            </Row>
            <Row>
              <Col className="fs_22 my-3 font-normal ff_heebo">
                Travel changes you. As you move through this life and this
                world, you change things slightly, you leave marks behind,
                however small. And in return, life — and travel — leaves marks
                on you."
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="text-end text-brown ff_heebo fs_30">
                  {" "}
                  <i>- Anthony Bourdain</i>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-md-5">
          <Container className="py-5">
            <Row>
              <Col className="col-md-5 col-12">
                <h3 className="text-end margin_bottom_space">
                  The Beginning of
                </h3>
                <img className="w-100" src={logo_img} alt="logo_img" />
              </Col>
              <Col className="fs_17 col-md-7 ff_heebo col-12">
              Oryon Luxury Travel was born from a shared passion for exploration by a small,
dedicated team spread across the globe. After years of meticulous travel and
experience, we saw a unique opportunity to bring our love for travel to you.
              </Col>
            </Row>
            <Row>
              <Col className="fs_17 mt-3 ff_heebo col-12">
              We set out to create something different—luxury travel that goes beyond the expected.
From the cities we recommend to the hotels, restaurants, and activities we choose,
every detail is carefully selected and trusted by our team or our global network. We’re
constantly exploring new places, seeking the finest experiences, and ensuring that every
trip we design is unforgettable.
              </Col>
              <Col className="fs_17 mt-3 ff_heebo col-12">
              At Oryon, we don’t just plan vacations. We craft journeys that are as unique as you are,
turning your travel dreams into a reality with style, ease, and luxury.
              </Col>
            </Row>
          </Container>
        </section>

        <section className="py-4">
          <Container fluid className="position-relative">
            <div className="position-absolute d-md-block d-none bg-section-primary end-0 top-0 h-100 w-75"></div>
            <Container>
              <Row>
                <Col className="col-md-2 col-12 d-flex align-items-center justify-content-md-end justify-content-center position-relative z-index-10 p-0 me-n">
                  <img
                    src={ImgView3}
                    className="img-fluid rounded-left-top border-brown"
                  />
                </Col>
                <Col className="col-md-10 col-12 mt-4 mt-md-0">
                  <Container className="bg-section-primary rounded-left-top skew-left p-4 position-relative">
                    <div className="shape-long-rect position-absolute top-0 end-0"></div>
                    <Row>
                      <Col className="offset-md-2">
                        <div className="fs_30 font-medium mt-3 text-brown">
                        OUR FOUNDER
                        </div>
                        <div className="fs_17 mt-4 pt-4">
                          <p className="ff_heebo">
                          After over 15 years of exploring the world and being immersed in the luxury travel industry,
Johanna has built deep expertise, cultivated lasting connections, and partnered on a variety of
projects that have shaped her journey. This wealth of experience inspired her to create
Oryon—a new way to experience travel, designed for those who crave something truly
extraordinary.
                          </p>
                          <p className="ff_heebo">
                          Oryon represents Johanna’s commitment to offering an entirely personalized, seamless travel
experience. From handpicking hidden gems to tailoring every detail, she has mastered the art of
designing journeys that are as unique as each traveler. “Spontaneous adventures can be
thrilling, but knowing everything is perfectly planned ahead of time allows you to immerse
yourself in a destination without a single worry,” Johanna shares.

                          </p>
                          <p className="ff_heebo">
                          Before Oryon, Johanna and her team often found it challenging to find the kind of travel
experiences they were truly seeking—ones that felt custom-designed, fresh, and exciting. Too
often, they encountered pre-packaged itineraries that didn’t quite hit the mark. It became clear
that what was missing was a deeper level of personalization and expertise—something Oryon
now delivers with every journey.

                          </p>
                          <p className="ff_heebo">
                          At Oryon, Johanna believes luxury travel is all in the details—trusted partners, seamless
logistics, and experiences that go above and beyond. It’s this attention to detail that enables her
to craft unforgettable, world-class itineraries for every traveler, every time.

                          </p>
                          <p className="ff_heebo">
                          With Oryon, Johanna is not just offering travel; she’s offering a way to experience the world like
never before.

                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>

        <section className="position-relative bg_newleeter min-vh-100">
          <img src={shed} className="w-100 position-absolute top-0 img_fluid" />
          <div className=" pt-5">
            {/* <img src={ImgSection1} className="w-100" /> */}
            <div className="position-absolute h-100 w-100 left-0 top-50 translate-middle-y  pt-5 d-flex align-items-center">
              <Container>
                <Row>
                  <Col className="col-md-6 d-flex align-items-center col-12">
                    <div className="rounded-4 bg-white p-5">
                      <div className="text-brown my-3">
                        <h4 className=" fs_24">
                          {" "}
                          Subscribe to Our Newsletter{" "}
                        </h4>
                      </div>
                      <div>
                      Receive firsthand updates on exclusive destinations, expert tips, and exceptional luxury
                      travel experiences.
                      </div>

                      <div className="my-3">
                        <input
                          className="form-control-lg border-brown w-75"
                          placeholder="Enter Email"
                        ></input>
                      </div>
                      <button className="btn btn-primary px-5 py-2">
                        SUBSCRIBE
                      </button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default HomePage;

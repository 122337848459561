import React from "react";
import aboutus_image from "../../assets/images/aboutus_image.png";
import about_position_image from "../../assets/images/about_position_image.png";
import about_icon from "../../assets/images/about_icon.png";
import about_icon1 from "../../assets/images/about_icon1.png";
import about_icon2 from "../../assets/images/about_icon2.png";
import about_icon3 from "../../assets/images/about_icon3.png";
import about_icon4 from "../../assets/images/about_icon4.png";
import about_icon5 from "../../assets/images/about_icon5.png";

const AboutUsHero = () => {
  return (
    <div className=" custom_bg pb-5 custom_margin_country_hero">
      <div className="position-relative">
        <img
          className="w-100"
          height={503}
          src={aboutus_image}
          alt="aboutus_image"
        />

        <div className=" position-absolute top-50 translate-middle about_position ">
          <h4 className="mb-0  fs_30 fw-medium ff_heebo text-white">
            ABOUT US
          </h4>
          <p className="mb-0  mt-2 fs_16 fw-normal ff_heebo text-white">
            We aim to facilitate your ultimate travel<br></br> experience in all
            ways possible
          </p>
        </div>
      </div>
      <div className="container ">
        <div className="row">
          <div className="col-lg-5 pt-4">
            <img
              className="w-100 image_margin "
              src={about_position_image}
              alt="about_position_image"
            />
          </div>
          <div className="col-lg-7">
            <div className="about_card image_margintop w-100 custom_pading pe-4">
            <div className="ps-4">
            <h4 className=" fs_30 fw-medium ff_heebo">
              Discover a New Standard of Travel!
              </h4>
              <p className=" fs_16 fw-normal ff_heebo custom_linehight">
              At Oryon, we redefine luxury travel by curating bespoke experiences that are as unique as you
are. Our expert team specializes in crafting unforgettable journeys tailored to your desires,
ensuring every detail aligns with your vision and exceeds your expectations.
              </p>
              <h4 className=" fs_30 fw-medium ff_heebo">
              Turning Dreams into Reality
              </h4>
              <p className=" fs_16 fw-normal ff_heebo custom_linehight ">
              <p  >Embark on a journey to some of the world’s most exclusive and awe-inspiring destinations, at
any time of year and across every continent. The extraordinary is within your reach, and we’re
here to make it happen.</p>
              </p>
              <h4 className=" fs_30 fw-medium ff_heebo">
              Personalized Itinerary, Just for You
              </h4>
              <p className=" fs_16 fw-normal ff_heebo custom_linehight">
              Simply complete the form on our homepage, and we’ll reach out to discuss your travel
preferences. From there, we’ll design a custom itinerary crafted exclusively for you, ensuring
every moment is perfectly tailored to your tastes.
              </p>
              <h4 className=" fs_30 fw-medium ff_heebo">
              Seamless Luxury from Start to Finish
              </h4>
              <p className=" fs_16 fw-normal ff_heebo custom_linehight">
              From the moment you arrive to the moment you depart, we handle every aspect of your journey.
Whether it's luxurious accommodations, seamless transportation, or curated experiences, every
detail will be arranged with precision, at your preferred pace.
              </p>
              <h4 className=" fs_30 fw-medium ff_heebo">
              Flexible Options for Every Traveler
              </h4>
              <p className=" fs_16 fw-normal ff_heebo custom_linehight">
              Should your preferences lean towards a more classic travel plan or a simpler itinerary, we are
happy to accommodate. Simply submit the form with your specifications, and we'll tailor our
services accordingly.
              </p>
</div>
            </div>
          </div>
        </div>
        <h4 className="mt-5 pt-4 fs_30 fw-medium ff_heebo color_primary text-center">
          {/* Why choose Oryon */}
        </h4>
        <div className="row mt-4">
          <div className="col-lg-2 col-sm-4 col-6">
            <div className=" bg_dark_brown rounded-2 d-flex justify-content-center align-items-center py-4">
              <img src={about_icon} alt="about_icon" />
            </div>
            <p className="text-center mt-2 fs_24 fw-normal">Honeymoons</p>
          </div>
          <div className="col-lg-2 col-sm-4 col-6">
            <div className=" bg_dark_brown rounded-2 d-flex justify-content-center align-items-center py-4">
              <img src={about_icon1} alt="about_icon" />
            </div>
            <p className="text-center mt-2 fs_24 fw-normal">Sun</p>
          </div>
          <div className="col-lg-2 col-sm-4 col-6">
            <div className=" bg_dark_brown rounded-2 d-flex justify-content-center align-items-center py-4">
              <img src={about_icon2} alt="about_icon" />
            </div>
            <p className="text-center mt-2 fs_24 fw-normal">Ski</p>
          </div>
          <div className="col-lg-2 col-sm-4 col-6">
            <div className=" bg_dark_brown rounded-2 d-flex justify-content-center align-items-center py-4">
              <img src={about_icon3} alt="about_icon" />
            </div>
            <p className="text-center mt-2 fs_24 fw-normal">Safari</p>
          </div>
          <div className="col-lg-2 col-sm-4 col-6">
            <div className=" bg_dark_brown rounded-2 d-flex justify-content-center align-items-center py-4">
              <img src={about_icon4} alt="about_icon" />
            </div>
            <p className="text-center mt-2 fs_24 fw-normal">Cruises</p>
          </div>
          <div className="col-lg-2 col-sm-4 col-6">
            <div className=" bg_dark_brown rounded-2 d-flex justify-content-center align-items-center py-4">
              <img src={about_icon5} alt="about_icon" />
            </div>
            <p className="text-center mt-2 fs_24 fw-normal">Groups</p>
          </div>
        </div>
      </div>
      <div className="bg_light_brown py-5 mt-5">
        <p className="text-center fw-normal fs_16 ff_heebo">
          "Travel changes you. As you move through this life and this world, you
          change things slightly, you leave marks behind, however<br></br>{" "}
          small. And in return, life — and travel — leaves marks on you.
          <br></br>"{" "}
          <span className=" fw-bold ff_heebo  color_primary">
            - Anthony Bourdain
          </span>
        </p>
      </div>
    </div>
  );
};

export default AboutUsHero;

import React from "react";

const WhyChooseOryon = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h4 className=" fs_30 text-center ff_heebo fw-medium color_primary">
          Why Choose Oryon
        </h4>
        <div className="px-5 mt-4">
          <ul className="ps-5">
            <li className="mt-2">
            <b>Best Pricing & Transparency:</b> No hidden costs or fees—just transparent, value-driven
            deals.
            </li>
            <li className="mt-2">
            <b>Expert Recommendations:</b> Tailored advice on the best seasons and destinations for
your perfect experience.
            </li>
            <li className="mt-2">
            <b>Comprehensive Travel Information:</b> All necessary details, including visa requirements,
provided upfront.
            </li>
            <li className="mt-2"><b>24/7 Support:</b> Dedicated assistance throughout your journey, whenever you need it.</li>
            <li className="mt-2">
            <b>Local Expertise:</b> Access to top local experts and exclusive connections for a seamless
            experience.
            </li>
            <li className="mt-2">
            <b>Added Value:</b> Enjoy personalized benefits and curated experiences that enhance your
trip.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseOryon;

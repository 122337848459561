import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const URL = `${API_URL}/web/continentsGetWeb`
export function getContinentsFunction() {
    return axios.get(URL);
}

export function getRegionFunction(name) {
    // const name = 'Asia'
    const URL_GET_REGION = `${API_URL}/web/getRegionCountry?name=${name}`
    return axios.get(URL_GET_REGION);
}


export function getCountryFunction(id) {
    // const name = 'Asia'
    const URL_GET_REGION = `${API_URL}/web/getSingleCountry/${id}`
    return axios.get(URL_GET_REGION);
}
export function getCurrencyFunction() {
    const URL_GET_Currency = `${API_URL}/web/currencyListWeb`
    return axios.get(URL_GET_Currency);
}

export function getCurrencyConvertFunction(selectedCurrency, selectedCurrency2, val) {
    const URL_GET_Currency = `${API_URL}/web/currencyConvert`;

    // Data to send in the body
    const data = {
        toCurrency: selectedCurrency,
        fromCurrency: selectedCurrency2,
        amount: val
    };

    // Sending data with a POST request
    return axios.post(URL_GET_Currency, data);
}

export function createFunction(data) {
    console.log("data data data",data);
    const URL_GET_Currency = `${API_URL}/web/webtravelCreate`;

    // Sending data with a POST request
    return axios.post(URL_GET_Currency, data);
}

export function abcFunction(data) {
    console.log("data data data",data);
    const URL_GET_Contect = `${API_URL}/web/webContectCreate`;

    // Sending data with a POST request
    return axios.post(URL_GET_Contect, data);
}
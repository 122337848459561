import React, {
  useEffect,
  useState,
} from 'react';

import tree_img from '../../assets/images/tree_img.png';

const FunFacts = ({data}) => {

  const [Couple, setCouple] = useState([]);

  useEffect(() => {
    if (data && data.funFacts && Array.isArray(data.funFacts)) {
      setCouple(data.funFacts);
    }
  }, [data]);

  const slicedArray = Couple.slice(0, Couple.length - 1);
  return (
    <div className="pt-5 custom_top_margin">
      <div className="container pt-5">
        <h4 className=" fs_30 ff_heebo fw-medium ">Fun facts</h4>
        <div className="d-md-flex gap-3 align-items-end">
          <p>
          <ul className="mt-3 ps-3">
                {slicedArray.map((item, index) => (
                  <li key={index}>
                    <p
                      className="fs_17 ff_heebo fw-normal"
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                  </li>
                ))}
              </ul>
          </p>
          {/* <div className=" mt-4 mt-md-0">
            <img width={245} height={286} src={tree_img} alt="tree_img" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FunFacts;
